.lyrics p {
    font-size: 2em;
    line-height: 2em;
    font-weight: 600;
}

.lyrics p span.line {
    transition: opacity 200ms;
}

.lyrics p span.line span.time {
    margin-left: 0.5rem;
    font-size: 0.8em;
    color: #888;
    display: none;
}

.lyrics p span.line {
    cursor: default;
}

.lyrics.audio-loaded p span.line.has-time {
    cursor: pointer;
}

.lyrics p.other-character {
    /* opacity: 0.4; */
    visibility: hidden;
}

.lyrics.keep-track p span.line:not(.active) {
    opacity: 0.6;
}

.lyrics p.interleaving.other-character {
    display: none!important;
}