.editor div.block {
    
}

.character-selector .character {
    cursor: pointer;
}

.character-selector .character:not(.active) {
    opacity: 0.3;
}

.line span.clickable {
    cursor: pointer;
}

.metadata, .lyrics {
    flex: 1;
}

.lyrics .scrollable {
    overflow: scroll;
}
