.coloured-character.blue {
    color: #06c;
}

.coloured-character.green {
    color: #093;
}

.coloured-character.red {
    color: #a00;
}

.coloured-character-background.blue {
    background: #06c!important;
    color: #fff!important;
}

.coloured-character-background.green {
    background: #093!important;
    color: #fff!important;
}

.coloured-character-background.red {
    background: #a00!important;
    color: #fff!important;
}